<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button />
        </ion-buttons>
        <ion-title>{{ $route.name }}</ion-title>
        <ion-button @click="goToHome()" color="primary" fill="clear" slot="end">
          <ion-icon icon="home" />
        </ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-textarea class="ion-text-center" v-if="!existsDossier">
        Non sono presenti fascicoli
      </ion-textarea>
      <ion-button fill="clear" @click="goToParent()" v-if="parent">
        <ion-icon icon="arrow-back" slot="start" />
        Indietro
      </ion-button>
      <ion-radio-group
        v-model="selectedDossierDocument"
        @ionChange="selectDoc(dd)"
      >
        <!--ion-item
          lines="none"
          v-for="dd in dossierDocuments"
          :key="dd.id"
          @click="goToChildren(dd)"
        -->
        <ion-row
          v-for="dd in dossierDocuments"
          @click="goToChildren(dd)"
          :key="dd.id"
        >
          <ion-col size="1" class="ion-padding-start ion-padding-top">
            <ion-icon v-if="dd.isDossier" icon="folder" />
            <ion-icon v-if="!dd.isDossier" icon="document" />
          </ion-col>
          <ion-col size="10" class="ion-no-padding">
            <ion-textarea
              style="overflow:hidden"
              readonly
              autoGrow
              :value="dd.name"
              type="text"
              maxlength="500"
            >
            </ion-textarea>
          </ion-col>
          <ion-col size="1" class="ion-padding-end ion-padding-top">
            <ion-icon v-if="dd.isDossier" icon="arrow-forward" />
            <ion-radio v-if="!dd.isDossier" :value="dd" />
          </ion-col>
        </ion-row>
        <!--/ion-item-->
      </ion-radio-group>

      <ion-row v-if="selectedDossierDocument">
        <ion-col size="12">
          <ion-card>
            <ion-card-content>
              <div v-html="selectedDossierDocument.text" />
            </ion-card-content>
          </ion-card>

          <!--div
            class="text padding-start"
            v-html="selectedDossierDocument.text"
          /-->
        </ion-col>
      </ion-row>
      <ion-loading
        :is-open="loading"
        cssClass="loading"
        message="Caricamento..."
        :duration="timeout"
      />
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeMount, onMounted } from 'vue';
import { mapState, useStore } from 'vuex';

import {
  document,
  search,
  arrowForward,
  arrowBack,
  folder,
} from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { Document } from '@/models/document.model';
import { SearchData } from '@/models/search.model';
import { JnService } from '@/services/jn.service';
import { Categories } from '@/models/enums.model';
import { useRouter, useRoute } from 'vue-router';
import { Dossier } from '@/models/dossier.model';
import { DossierDocument } from '@/models/dossier.document.model';
import { useCookie } from '@vue-composable/cookie';
import * as eventBus from '../../services/event.bus.js';

addIcons({
  document: document,
  search: search,
  'arrow-forward': arrowForward,
  'arrow-back': arrowBack,
  folder: folder,
});

import {
  IonContent,
  IonButtons,
  IonMenuButton,
  IonMenuToggle,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonList,
  IonLoading,
  IonFab,
  IonFabButton,
  IonIcon,
  modalController,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonCheckbox,
  IonButton,
  IonRadioGroup,
  IonRadio,
  IonTextarea,
  IonCard,
  IonCardContent,
  alertController,
} from '@ionic/vue';

export default defineComponent({
  //inheritAttrs: false,
  name: 'JnDossier',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    // IonButtons,
    //IonMenuToggle,
    // IonTitle,
    // IonMenuButton,
    //  IonGrid,
    IonRow,
    IonCol,
    //  IonText,
    //IonItem,
    //IonCheckbox,
    //  IonList,
    IonLoading,
    IonIcon,
    IonButtons,
    IonButton,
    IonMenuButton,
    IonTitle,
    IonRadioGroup,
    IonRadio,
    IonTextarea,
    IonCard,
    IonCardContent,
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const docsSize = ref();
    const scrollComponent = ref(null);

    const loading = ref(true);
    const timeout = ref(5000);
    const existsDossier = ref(false);

    function goToHome() {
      // eventBus.default.$emit('reset-home-results');
      router.push('/main');
    }

    const showAlert = async (message: string) => {
      const alert = await alertController.create({
        header: 'Errore',
        message: message,
        buttons: [
          {
            text: 'Riprova',
            role: 'retry',
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
    };

    /**
     * INIZIO - Gestione Dossier
     */
    const docs = ref([] as DossierDocument[]);
    const dossier = ref<Dossier>();
    const dossierDocuments = ref([] as DossierDocument[]);
    const parent = ref<DossierDocument | undefined>();
    const selectedDossierDocument = ref<DossierDocument | undefined>();

    const goToChildren = (dossierDocument: DossierDocument) => {
      if (dossierDocument.hasChildren()) {
        parent.value = dossierDocument;
        dossierDocuments.value = [];
        for (const se of dossierDocument.children) {
          dossierDocuments.value.push(se);
        }
      }
    };

    const goToParent = async () => {
      dossierDocuments.value = [];
      selectedDossierDocument.value = undefined;
      if (parent.value && parent.value.parent) {
        const parentParent = parent.value!.parent;
        parent.value = parentParent;
        for (const se of parentParent.children) {
          dossierDocuments.value.push(se);
        }
      } else {
        parent.value = undefined;
        for (const se of dossier.value!.children) {
          dossierDocuments.value.push(se);
        }
      }
    };

    const selectDoc = async () => {
      //console.log(selectedDossierDocument.value);
      docs.value = [];
      selectedDossierDocument.value!.text = (
        await JnService.getDossierDocument(selectedDossierDocument.value!.id)
      ).text;
      /*

      const selected = ref([] as DossierDocument[]);
      dossier.value?.getAllSelectedChildren(selected.value);
      for (const s of parent.value?.children!) {
        s.selected = false;
        s.text = undefined;
      }
      docs.value = [];
      if (dd.selected) {
        dd.text = (await JnService.getDossierDocument(dd.id)).text;
        // docs.value.push(dd);
      } else {
        docs.value = [];
      }*/
    };
    /**
     * FINE - Gestione Dossier
     */

    async function loadDossier() {
      loading.value = true;
      const usernameCookie = useCookie('username');
      dossierDocuments.value = [];
      try {
        dossier.value = await JnService.getDossier(
          usernameCookie.cookie.value!
        );
        for (const dd of dossier.value.children) {
          dossierDocuments.value.push(dd);
        }
        existsDossier.value = true;
      } catch (err) {
        loading.value = false;
        // showAlert(err.message);
      }
      loading.value = false;
    }

    onMounted(async () => {
      eventBus.default.$on('reset', function(data: string) {
        loadDossier();
      });
      loadDossier();
    });

    return {
      scrollComponent,
      timeout,
      loading,
      goToParent,
      goToChildren,
      dossierDocuments,
      parent,
      selectDoc,
      docs,
      goToHome,
      selectedDossierDocument,
      existsDossier,
    };
  },
});
</script>
